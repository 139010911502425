
  import { Vue, Options } from 'vue-class-component'

  @Options({
    props: {
      title: String,
      link1: String,
      link2: String,
    },
    computed () {
      this.link1 = this.title
      if (!this.link1) {
      }
      if (this.link1 && !this.link2) {
        this.link2 = this.title
      }
    }
  })

  export default class Header extends Vue {}
