import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import { staticFactory } from '@/http/static'

@Options({
  components: {
    Header
  },
  data() {
    return {
      students: [],
      studentPayments: []
    }
  },
  methods: {

  },
  async mounted () {
    await Promise.all([
      staticFactory.students(),
      staticFactory.studentPayments()
    ]).then(results => {
      this.students = results[0],
      this.studentPayments = results[1]
    })
  },
})

export default class Payment extends Vue {}
