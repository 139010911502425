import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import { staticFactory } from '@/http/static'

@Options({
  components: {
    Header
  },
  data() {
    return {
      student: {},
      billDetails: [],
      userPayments: [],
      months: [],
      totalPaid: 0,
      totalAmount: 0
    }
  },
  methods: {

  },
  async mounted () {
    await Promise.all([
      staticFactory.students(),
      staticFactory.months(),
      staticFactory.userPaymentHistory()
    ]).then(results => {
      this.student = results[0][0],
      this.months = results[1],
      this.userPayments = results[2].reverse()
    })

    this.userPayments.forEach((payment:any) => {
      if (payment.status == 2) this.totalPaid += payment.total_amount
      if (payment.status != 2) this.totalAmount += payment.total_amount
    })
  },
})

export default class UserPayment extends Vue {}
