import axios from 'axios'


export interface MasterBill {
  name: string,
  amount: number,
  type: string,
  months: number[]
}

export interface StudentBill {
  [key: string]: Bill
}

export interface Bill {
  [key: number]: number
}

export interface TotalBill {
  [key: string]: number
}

export interface BillDetailModel {
  name: string,
  status: number,
  month: number,
  deadline: number,
  total_amount: number,
  total_paid: number
}

class StaticFactory {

  async masterBill () {
    try {
      var response = await axios.get('/data/master_bills.json')
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async months () {
    try {
      var response = await axios.get('/data/months.json')
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async students () {
    try {
      var response = await axios.get('/data/students.json')
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async billStatus () {
    try {
      var response = await axios.get('/data/bill_status.json')
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async billDetail () {
    try {
      var response = await axios.get('/data/bill_details.json')
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async studentPayments () {
    try {
      var response = await axios.get('/data/student_payments.json')
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async userPaymentHistory () {
    try {
      var response = await axios.get('/data/user_payments.json')
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async transactionMaps () {
    try {
      var response = await axios.get('/data/transactions')
      var data = response.data.data

      if (data.transaction_maps) {
        return data.transaction_maps
      }

      return {}
    } catch (error) {
      console.error(error)
      return false
    }
  }

}

export let staticFactory = new StaticFactory()
