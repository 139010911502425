export default {
  get (key) {
    var value = null
    if (localStorage[key] !== null && localStorage[key] !== undefined) {
      value = cryptr.decrypt(localStorage[key])
    }
    return value
  },
  getRoleModule (key) {
    // need to decrypt first then json parse
    var valueReturn = {
      insert: 1,
      update: 1,
      delete: 1,
      read: 1
    }
    if (localStorage['admin_role_modules'] !== null && localStorage['admin_role_modules'] !== undefined) {
      var parsing = cryptr.decrypt(localStorage['admin_role_modules'])
      parsing = JSON.parse(parsing)
      parsing.forEach(function (value) {
        if (value.mst_module_name === key) {
          valueReturn.read = value.read_action
          valueReturn.insert = value.insert_action
          valueReturn.update = value.update_action
          valueReturn.delete = value.delete_action
        }
      })
    }
    return valueReturn
  },
  getUserRoleModule (key) {
    // need to decrypt first then json parse
    var valueReturn = {
      insert: 0,
      update: 0,
      delete: 0,
      read: 0
    }
    if (localStorage['role_modules'] !== null && localStorage['role_modules'] !== undefined) {
      var parsing = cryptr.decrypt(localStorage['role_modules'])
      parsing = JSON.parse(parsing)
      parsing.forEach(function (value) {
        if (value.mst_module_name === key) {
          valueReturn.read = value.read_action
          valueReturn.insert = value.insert_action
          valueReturn.update = value.update_action
          valueReturn.delete = value.delete_action
        }
      })
    }
    return valueReturn
  },
  set (key, value) {
    localStorage[key] = value
  },
  getAll () {
    const list = []
    const len = localStorage.length
    for (var i = 0; i < len; i++) {
      list[localStorage.key(i)] = localStorage.getItem(localStorage.key(i))
    }
    return list
  },
  clear () {
    localStorage.clear()
  },
  clearAdmin () {
    localStorage.removeItem('admin_id')
    localStorage.removeItem('admin_name')
    localStorage.removeItem('admin_email')
    localStorage.removeItem('admin_phone')
    localStorage.removeItem('admin_role')
    localStorage.removeItem('admin_token')
    localStorage.removeItem('admin_role_modules')
  },
  clearUser () {
    localStorage.removeItem('user_id')
    localStorage.removeItem('user_name')
    localStorage.removeItem('user_email')
    localStorage.removeItem('user_phone')
    localStorage.removeItem('user_role')
    localStorage.removeItem('token')
    localStorage.removeItem('role_modules')
  },
  remove (key) {
    localStorage.removeItem(key)
  }
}
