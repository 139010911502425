import { Vue, Options } from 'vue-class-component'
import update from './mixins/update'

@Options({
  created () {
    if (this.updateExists) {
      var r = confirm("update app?")
      if (r) {
        this.refreshApp()
      }
    }
  },
  mixins: [ update ]
})

export default class App extends Vue {}
