
  import { Vue, Options } from 'vue-class-component'

  @Options({
    data() {
      return {
        links: [
        {
            id: 1,
            text: 'Rekap',
            page: '/'
        },
        {
            id: 2,
            text: 'Master Tagihan',
            page: '/bill'
        },
        {
            id: 3,
            text: 'Tagihan Siswa',
            page: '/bill/user'
        },
        {
            id: 4,
            text: 'Pembayaran',
            page: '/payment'
        }]
      }
    }
  })

  export default class Navbar extends Vue {}
