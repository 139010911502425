import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "nav nav-pills nav-justified nav-top mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock("nav", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.links, (routes) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        class: "nav-link",
        key: routes.id,
        "active-class": "active-nav",
        to: `${routes.page}`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(routes.text), 1)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}