import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { prototypeRoutes } from './prototype'
import { defaultRoutes } from './default'

var routes = defaultRoutes
if (process.env.NODE_ENV == 'prototype') {
  routes = prototypeRoutes
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

export default router
