import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import { staticFactory, StudentBill, TotalBill } from '@/http/static'

@Options({
  components: {
    Header
  },
  data() {
    return {
      masterBills: [],
      months: [],
      students: [],
      billTypes: [ "spp", "kegiatan", "ujian"],
      studentBills: [],
      totalBill: {
        spp: 0,
        kegiatan: 0,
        ujian: 0
      }
    }
  },
  methods: {
    tableDataClass(student:number, type:string, month:number) {
      var isSuccess, isPending, isDeadline = false

      status = 0
      if (this.billStatus[student]) {
        if (this.billStatus[student][type]) {
          var status = this.billStatus[student][type][month]
        }
      }

      if (status == 1) {
        isSuccess = true
      } else if (status == 2) {
        isPending = true
      } else if (status == 3) {
        isDeadline = true
      }

      return {
        'bg-success-strip text-success': isSuccess,
        'bg-warning-strip text-warning': isPending,
        'bg-danger-strip text-danger': isDeadline,
      }
    }
  },
  async mounted () {
    await Promise.all([
      staticFactory.masterBill(),
      staticFactory.months(),
      staticFactory.students(),
      staticFactory.billStatus()
    ]).then(results => {
      this.masterBills = results[0]
      this.months = results[1]
      this.students = results[2],
      this.billStatus = results[3]
    })

    let studentBills: StudentBill = {}
    var totalBill: TotalBill = {}
    this.billTypes.forEach((type:string) => {
      var total = 0
      this.masterBills.forEach((bill:any) => {
        if (bill.type != type) {
          return
        }
        if (studentBills[type] == undefined) studentBills[type] = {}

        this.months.all.forEach((month:string, m:number) => {
          if (m < 6) return
          if (studentBills[type][m] == undefined) studentBills[type][m] = 0
          if (bill.months.indexOf(m) < 0) {
            return
          }
          studentBills[type][m] += bill.amount
          total += bill.amount
        })
      })

      totalBill[type] = total
    })

    this.totalBill = totalBill
    this.studentBills = studentBills
  },
})

export default class Dashboard extends Vue {}
