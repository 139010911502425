import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import * as bootstrap from 'bootstrap'

import { staticFactory } from '@/http/static'
import swal from 'sweetalert'

@Options({
  components: {
    Header
  },
  data() {
    return {
      billDetails: [],
      months: [],
      totalBill: 0,
      totalPaid: 0,
      totalUnpaid: 0,
      readMoreActivated: false
    }
  },
  methods: {
    save: function() {
      swal("Sukses menyimpan tagihan", "", "success")
    }
  },
  async mounted () {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    await Promise.all([
      staticFactory.billDetail(),
      staticFactory.months()
    ]).then(results => {
      this.billDetails = results[0],
      this.months = results[1]
    })

    this.billDetails.forEach( (detail:any) => {
      if (detail.status != 1) this.totalBill += detail.total_amount
      else this.totalPaid += detail.total_amount
    })
    this.totalUnpaid = (this.totalBill - this.totalPaid)
  },
})

export default class BillDetail extends Vue {}
