import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import swal from 'sweetalert'
import { staticFactory } from '@/http/static'

@Options({
  components: {
    Header
  },
  data() {
    return {
      students: [],
      billAdditionals: [
        {
          name: "Biaya",
          type: 0,
          amount: 400000
        },
        {
          name: "Keringanan",
          type: 1,
          amount: 0
        },
      ],
      totalBill: 0,
      months: [ 
        { "id": "1", "name": "Januari"},
        { "id": "2", "name": "Februari"},
        { "id": "3", "name": "Maret"},
        { "id": "4", "name": "April"},
        { "id": "5", "name": "Mei"},
        { "id": "6", "name": "Juni"},
        { "id": "7", "name": "Juli"},
        { "id": "87", "name": "Agustus"},
        { "id": "9", "name": "September"},
        { "id": "10", "name": "Oktober"},
        { "id": "11", "name": "November"},
        { "id": "12", "name": "Desember"}
      ],
      allSelectedMonth: false,
      monthIds: [],
      classes: [ 
        { "id": "1", "name": "VII A"},
        { "id": "2", "name": "VII B"},
        { "id": "3", "name": "VII C"},
        { "id": "4", "name": "VIII A"},
        { "id": "5", "name": "VIII B"},
        { "id": "6", "name": "VIII C"},
        { "id": "7", "name": "IX A"},
        { "id": "8", "name": "IX B"},
        { "id": "9", "name": "IX C"}
      ],
      selectedClass: [],
      allSelectedClass: false,
      classIds: []
    }
  },
  methods: {
    save: function() {
      swal("Sukses menyimpan tagihan", "", "success")
    },
    generate: function() {
      swal("Sukses menghasilkan tagihan ke tiap siswa", "", "success")
    },
    addDetail: function() {
      this.billAdditionals.push({
        name: "",
        type: 0,
        amount: 0
      })
    },
    removeDetail: function(i:number) {
      this.billAdditionals.splice(i, 1)
    },
    checkAllMonth: function() {
      this.monthIds = [];

      if (!this.allSelectedMonth) {

        this.months.forEach((m:any) => {
          this.monthIds.push(m.id);
        })
      }
    },
    checkAllClass: function() {
      this.classIds = [];

      if (!this.allSelectedClass) {
          this.classes.forEach((c:any) => {
            this.classIds.push(c.id);
          })
      }
    },
    calculatePrice: function() {
      this.totalBill = 0
      this.billAdditionals.forEach((bill:any) => {
        if (bill.type == 1) {
          this.totalBill -= parseInt(bill.amount)
        } else {
          this.totalBill += parseInt(bill.amount)
        }
      })
    }
  },
  async mounted () {
    await Promise.all([
      staticFactory.students(),
    ]).then(results => {
      this.students = results[0]
    })

    this.billAdditionals.forEach((bill:any) => {
      this.totalBill += bill.amount
    })
  },
})

export default class Bill extends Vue {}
